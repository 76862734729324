<template>
    <!-- Contact Us Form -->
    <v-form ref="contactUsForm" :class="isDark?'custom-error-color-dark':'custom-error-color-light'" @submit.prevent="submit">
        <div>
            <v-text-field :dark="isDark"
            v-model="name"
            :error-messages="nameErrors"
            color="custom-accent darken-4"
            :label="$t('name')"
            :placeholder="$t('name')"
            required
            rounded
            outlined
            dense
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
            ></v-text-field>
        </div>
        <div>
            <v-text-field :dark="isDark"
            v-model="email"
            :error-messages="emailErrors"
            color="custom-accent darken-4"
            :label="$t('email')"
            :placeholder="$t('email')"
            required
            rounded
            outlined
            dense
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
            ></v-text-field>
        </div>
        <div>
            <v-text-field :dark="isDark"
            v-model="phone"
            :error-messages="phoneErrors"
            color="custom-accent darken-4"
            :label="$t('phone')"
            :placeholder="$t('phone')"
            required
            rounded
            outlined
            dense
            @input="$v.phone.$touch()"
            @blur="$v.phone.$touch()"
            ></v-text-field>
        </div>
        <div>
            <v-text-field :dark="isDark"
            v-model="subject"
            :error-messages="subjectErrors"
            color="custom-accent darken-4"
            :label="$t('subject')"
            :placeholder="$t('subject')"
            required
            rounded
            outlined
            dense
            @input="$v.subject.$touch()"
            @blur="$v.subject.$touch()"
            ></v-text-field>
        </div>
        <div>
            <v-textarea :dark="isDark"
            v-model="content"
            :error-messages="contentErrors"
            color="custom-accent darken-4"
            auto-grow
            rows="4"
            row-height="30"
            required
            rounded
            outlined
            dense
            @input="$v.content.$touch()"
            @blur="$v.content.$touch()"
            :label="$t('content')"
            :placeholder="$t('content')"
            ></v-textarea>
        </div>
        <div >
            <v-btn class="mr-2 form-btn" type="submit" :dark="isDark" :class="{'disable-events': $v.$invalid}" outlined rounded depressed>
            <span v-if="loading"><v-progress-circular indeterminate></v-progress-circular></span>
            <span v-else>{{$t("submit")}}</span>
            </v-btn>
            <v-btn class="form-btn" @click="clear" rounded depressed>
            {{$t("clear")}}
            </v-btn>
        </div>
    </v-form>
    <!-- Contact Us Form -->
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, maxLength, email } from 'vuelidate/lib/validators';

import { mapState,mapActions} from "vuex";

const phoneRegex = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
const phoneCustomValidator = (value) => phoneRegex.test(value)

export default {
  name: "ContactUsForm", 
  mixins: [validationMixin],
  props: ['isDark','type'],
  
  /* contact form validation settings */
  validations: {
    name: { required, maxLength: maxLength(35) },
    email: { required, email },
    phone: { required,phoneCustomValidator},
    subject: { required,maxLength: maxLength(45)},
    content: { required,maxLength: maxLength(250)},
  },

  data() {
    return {
        name:'',
        email:'',
        phone:'',
        subject:'',
        content:'',
    }
  },
  components:{
  },
  created(){
  },
  computed:{
    ...mapState("contact", {
      loading: "loading"
    }),

    maxNameLength () {
      return 35;
    },
    maxSubjectLength () {
      return 45;
    },
    maxContentLength () {
      return 250;
    },

    /* contact form validation testers */
    nameErrors () {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.maxLength && errors.push(this.$t('nameValidError1')+' '+this.maxNameLength+' '+this.$t('nameValidError2'))
      !this.$v.name.required && errors.push(this.$t('nameError'))
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push(this.$t('emailValidError'))
      !this.$v.email.required && errors.push(this.$t('emailError'))
      return errors
    },
    phoneErrors () {
      const errors = []
      if (!this.$v.phone.$dirty) return errors
      !this.$v.phone.required && errors.push(this.$t('phoneError'))
      !this.$v.phone.phoneCustomValidator && errors.push(this.$t('phoneValidError'))
      return errors
    },
    subjectErrors () {
      const errors = []
      if (!this.$v.subject.$dirty) return errors
      !this.$v.subject.maxLength && errors.push(this.$t('subjectValidError1')+' '+this.maxSubjectLength+' '+this.$t('subjectValidError2'))
      !this.$v.subject.required && errors.push(this.$t('subjectError'))
      return errors
    },
    contentErrors () {
      const errors = []
      if (!this.$v.content.$dirty) return errors
      !this.$v.content.maxLength && errors.push(this.$t('contentValidError1')+' '+this.maxContentLength+' '+this.$t('contentValidError2'))
      !this.$v.content.required && errors.push(this.$t('contentError'))
      return errors
    },
  },
  watch:{
  },
  methods:{
    submit () {
      this.$v.$touch();
      if(!this.$v.$invalid){
        let contactInfo={
          name:this.name,
          email:this.email,
          phone:this.phone.replace(/\D/g,""),
          subject:this.subject,
          content:this.content,
          type:this.type,
        };

        this.addContact(contactInfo).then(() => {
            this.$root.$emit('callSnackbar', { icon:'mdi-check-circle', state:true, content:this.$t('messagesent'), type:'dark'});
            this.clear();
          },() => {
            this.$root.$emit('callSnackbar', { icon:'mdi-alert', state:true, content:this.$t('error'), type:'warning'});
          }
        )

      }
    },
    clear () {
      this.$v.$reset();
      this.name = '';
      this.email = '';
      this.phone = '';
      this.subject = '';
      this.content = '';
    },

    ...mapActions("contact", ["addContact"]),
  },
};
</script>
<style scoped>
@import url('../assets/styles/contact.css');
</style>
 
